import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { NFCEvent } from "interfaces";
import { useCallback, useContext, useEffect } from "react";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import { getStorySourceCode } from "components/lib/common/string.helper";
import { AlertContext } from "App";

const SubmitBox = styled(Box)({
	position: "fixed",
	bottom: 25,
	left: 0,
	right: 0,
	width: "100%",
	height: 40,
	display: "flex",
	justifyContent: "center",
});

const SubmitButton = styled(Button)({
	borderRadius: 10,
	height: "100%",
	width: "85%",
});

interface CheckNfcProps {
	imageData: string[];
	setImageData: React.Dispatch<React.SetStateAction<string[]>>;
	isLoading: boolean;
	handleSumbit: Function;
}

const CheckNfc = ({
	imageData,
	isLoading,
	setImageData,
	handleSumbit,
}: CheckNfcProps) => {
	const { setModalAlert } = useContext(AlertContext);

	const NotiBox = styled(Box)({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "1px solid rgba(0, 0,0,0.5)",
		borderRadius: 5,
		fontSize: 14,
		color: imageData.length < 5 ? "red" : "green",
		marginTop: 6,
		padding: "6px 0",
		backgroundColor: "aliceblue",
	});

	const renderContentByLength = useCallback(() => {
		if (imageData.length < 5) {
			return `あと${5 - imageData.length}枚の読み込みが必要です`;
		} else {
			return "記憶ストーリーを生成できます";
		}
	}, [imageData]);

	const onReading = useCallback(
		({ message }: NFCEvent) => {
			for (const record of message.records) {
				switch (record.recordType) {
					case "url": {
						const urlDecoder = new TextDecoder();
						const urlString = urlDecoder.decode(record.data);
						const urlStorySourceCode = getStorySourceCode(urlString);

						if (!imageData.includes(urlStorySourceCode)) {
							setImageData((prev) => {
								if (!prev.includes(urlStorySourceCode) && prev.length === 10) {
									setModalAlert({
										isOpen: true,
										message: "ストーリー要素は10以下で指定してください。",
										type: "error",
									});
									return prev;
								} else if (!prev.includes(urlStorySourceCode)) {
									return [...prev, urlStorySourceCode];
								} else return prev;
							});
						}
						break;
					}
					default:
						console.log("message: ", message);
				}
			}
		},
		[imageData, setImageData, setModalAlert]
	);
	const scan = useCallback(async () => {
		if ("NDEFReader" in window) {
			try {
				const ndef = new window.NDEFReader();
				await ndef.scan();

				console.log("Scan started successfully.");
				ndef.onreadingerror = () => {
					console.log("Cannot read data from the NFC tag. Try another one?");
				};

				ndef.onreading = (event: any) => {
					console.log("NDEF message read.", event);
					onReading(event);
				};
			} catch (error) {
				console.log(`Error! Scan failed to start: ${error}.`);
			}
		} else {
			console.log("Not have NDEFReader");
		}
	}, [onReading]);

	useEffect(() => {
		scan();
	}, [scan]);

	useEffect(() => {
		const updateDynamicHeight = () => {
			document.documentElement.style.setProperty("--dynamic-height", `100%`);
		};

		window.addEventListener("resize", updateDynamicHeight);
		updateDynamicHeight();

		return () => window.removeEventListener("resize", updateDynamicHeight);
	}, []);

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box>
				<Typography align="center">
					記憶したいカードを読み込んでください
				</Typography>
				<NotiBox>{renderContentByLength()}</NotiBox>
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: 1,
					marginTop: 2,
					height: "calc(var(--dynamic-height) - 120px)",
				}}
			>
				{Array.from({ length: 10 }, (src, index) => (
					<Box key={index} sx={{ height: 160, maxWidth: "100%" }}>
						{imageData[index] ? (
							<Box
								position="relative"
								display="inline-block"
								width="100%"
								height="100%"
							>
								<img
									srcSet={`/images/${imageData[index]}.png`}
									src={`/images/${imageData[index]}.png`}
									alt=""
									loading="lazy"
									style={{
										objectFit: "cover",
										height: "100%",
										width: "100%",
									}}
								/>
								<IconButton
									aria-label="delete"
									sx={{
										position: "absolute",
										top: 0,
										right: 0,
										p: 1,
										zIndex: 10,
										color: "red",
									}}
									onClick={() => {
										const filteredData = [...imageData];
										filteredData.splice(index, 1);
										setImageData(filteredData);
									}}
								>
									<RemoveCircleOutlineTwoToneIcon />
								</IconButton>
							</Box>
						) : (
							<Box
								sx={{
									width: "100%",
									height: "100%",
									backgroundColor: "lightgray",
								}}
							></Box>
						)}
					</Box>
				))}
			</Box>
			<Box sx={{ width: "100%", height: 50 }}></Box>
			<SubmitBox>
				<SubmitButton
					variant="contained"
					disabled={imageData.length < 5 || isLoading}
					startIcon={
						isLoading ? (
							<CircularProgress size={20} color="inherit" />
						) : undefined
					}
					onClick={() => {
						handleSumbit();
					}}
				>
					記憶ストーリー生成
				</SubmitButton>
			</SubmitBox>
		</Box>
	);
};

export default CheckNfc;
