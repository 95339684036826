import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SafeArea from "./SafeArea";
import Footer from "./Footer";

interface CommonLayoutProps {
	children: React.ReactElement;
	title: string;
	center?: boolean;
}
const StickyBox = styled(Box)({
	position: "sticky",
	top: 0,
	backgroundColor: "white",
	borderBottom: "rgba(0, 0, 0, 0.5) solid 1px",
});

const CommonLayout = ({
	children,
	title,
	center = false,
}: CommonLayoutProps) => {
	return (
		<SafeArea>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					minHeight: "100vh",
				}}
			>
				<StickyBox display="flex" position="sticky" top={0}>
					<Typography sx={{ padding: 1.5 }}>{title}</Typography>
				</StickyBox>
				<Box
					sx={{
						padding: "12px 12px 0",
						flexGrow: 1,
						display: "flex",
						justifyContent: "stretch",
						alignItems: center ? "center" : "start",
					}}
				>
					{children}
				</Box>
				<Footer />
			</Box>
		</SafeArea>
	);
};

export default CommonLayout;
