import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/style/global";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
function AppWrapper() {
	const queryClient = new QueryClient();

	return (
		<React.StrictMode>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<App />
					</ThemeProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</React.StrictMode>
	);
}

root.render(<AppWrapper />);
