import { useState } from "react";
import CheckNfc from "./CheckNfc";
import StoryShow from "./StoryShow";
import { useCreateMemorizeAI } from "components/lib/api/memorize";
import { useLocation } from "react-router-dom";

const Memorize = () => {
	const [isCheckNfc, setIsCheckNfc] = useState(true);
	const [imageData, setImageData] = useState<string[]>([]);
	const location = useLocation();
	const storyTypeCode = location.pathname.startsWith("/")
		? location.pathname.substring(1)
		: location.pathname;

	const handleCreateMemorizeAI = useCreateMemorizeAI(() => {
		setIsCheckNfc(false);
	});

	const handleSumbit = () => {
		if (isCheckNfc) {
			handleCreateMemorizeAI.mutate({
				story_source_codes: imageData,
				story_type_code: storyTypeCode,
			});
		} else setIsCheckNfc(true);
	};

	if (isCheckNfc)
		return (
			<CheckNfc
				imageData={imageData}
				setImageData={setImageData}
				isLoading={handleCreateMemorizeAI.isPending}
				handleSumbit={handleSumbit}
			/>
		);
	else
		return (
			<StoryShow
				imageData={imageData}
				handleSumbit={handleSumbit}
				stories={handleCreateMemorizeAI?.data?.data}
			/>
		);
};

export default Memorize;
