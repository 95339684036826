import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IContentStory } from "interfaces";

const StoryBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	border: "1px solid rgba(0, 0,0,0.5)",
	borderRadius: 5,
	marginTop: 6,
	padding: 10,
	backgroundColor: "aliceblue",
});

const BackButton = styled(Button)({
	borderRadius: 10,
	height: 40,
	marginTop: 20,
});

const TitleTypography = styled(Typography)({
	fontWeight: "bold",
});
const ContentTypography = styled(Typography)({
	margin: "6px 0",
});

interface StoryShowProps {
	imageData: string[];
	handleSumbit: Function;
	stories: IContentStory;
}

const StoryShow = ({ imageData, handleSumbit, stories }: StoryShowProps) => {
	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography align="center">ストーリーをお楽しみください</Typography>
			<StoryBox>
				{stories?.contents?.map((story, storyIndex) => (
					<Box key={storyIndex} sx={{ marginBottom: 2 }}>
						<TitleTypography>{story.title}</TitleTypography>
						<Box
							key={0}
							sx={{
								height: 280,
								maxWidth: "100%",
								margin: "5px 0",
								display:
									storyIndex === stories.contents.length - 1 ? "none" : "block",
							}}
						>
							<img
								srcSet={`/images/${imageData[storyIndex]}.png`}
								src={`/images/${imageData[storyIndex]}.png`}
								alt=""
								loading="lazy"
								style={{
									objectFit: "cover",
									height: "100%",
									width: "100%",
								}}
							/>
						</Box>
						<ContentTypography>{story.contents}</ContentTypography>
					</Box>
				))}
			</StoryBox>
			<BackButton variant="contained" onClick={() => handleSumbit()}>
				戻る
			</BackButton>
		</Box>
	);
};

export default StoryShow;
