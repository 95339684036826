import React, { SyntheticEvent } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Alert, ClickAwayListener } from "@mui/material";

interface AlertMessageProps {
	open: boolean;
	setOpen: Function;
	severity: "error" | "success" | "info" | "warning";
	message: string | string[];
	autoHideDuration?: number;
}

const AlertMessage = ({
	open,
	setOpen,
	severity,
	message,
	autoHideDuration = 6000,
}: AlertMessageProps) => {
	const handleCloseAlertMessage = (
		event: Event | SyntheticEvent<any, Event>,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") return;
		setOpen(false);
	};
	if (open) {
		return (
			<ClickAwayListener
				onClickAway={() => {
					setOpen(false);
				}}
			>
				<Snackbar
					open={open}
					autoHideDuration={autoHideDuration}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					onClose={handleCloseAlertMessage}
				>
					<Alert
						onClose={handleCloseAlertMessage}
						severity={severity}
						sx={{
							"& .MuiAlert-icon": {
								margin: "auto",
								marginRight: 1,
							},
						}}
					>
						{typeof message === "string"
							? message
							: (message as string[])?.map((msg, index) => (
									<p key={msg + index}>{msg}</p>
							  ))}
					</Alert>
				</Snackbar>
			</ClickAwayListener>
		);
	} else return <></>;
};

export default AlertMessage;
