import { createTheme } from "@mui/material/styles";

const primaryMainColor = "#0A97D9";
const secondaryMainColor = "#E9EAFE";

const theme = createTheme({
	palette: {
		primary: {
			main: primaryMainColor,
		},
		secondary: {
			main: secondaryMainColor,
		},
	},
	typography: {
		fontFamily: [
			"Noto Sans JP",
			"-apple-system",
			"BlinkMacSystemFont",
			"Segoe UI",
			"Roboto",
			"Oxygen",
			"Ubuntu",
			"Cantarell",
			"Fira Sans",
			"Droid Sans",
			"Helvetica Neue",
		].join(","),
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "20px",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: "#007fc6",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: "20px",
					boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.23)",
				},
			},
		},
		MuiPagination: {
			styleOverrides: {
				root: {
					margin: "1rem 0 1rem 0",
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					backgroundColor: primaryMainColor,
					color: "#ffffff",
					fontWeight: 800,
					fontSize: "1.5rem",
					textAlign: "center",
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					backgroundColor: "#e9f6fe",
					":last-child": {
						paddingBottom: 16,
					},
				},
			},
		},
	},
});

export default theme;
