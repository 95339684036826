import { useMutation } from "@tanstack/react-query";
import { AlertContext } from "App";
import { AxiosError } from "axios";
import { BaseErrorResponse, BaseResponse } from "interfaces";
import { useContext } from "react";
import { axiosInstanceAuth } from "./client";

export const useCreateMemorizeAI = (onSuccess: Function) => {
	const { setModalAlert } = useContext(AlertContext);
	return useMutation<
		BaseResponse<any>,
		AxiosError,
		{
			story_source_codes: string[];
			story_type_code: string;
		}
	>({
		mutationKey: ["CREATE MEMORIZE AI"],
		mutationFn: (params: {
			story_source_codes: string[];
			story_type_code: string;
		}): Promise<BaseResponse<any>> =>
			axiosInstanceAuth.get(
				`/story?story_source_codes=${params.story_source_codes.join(
					","
				)}&story_type_code=${params.story_type_code}`
			),
		onSuccess: () => {
			onSuccess();
		},
		onError: (err) => {
			const baseError = err as BaseErrorResponse;
			setModalAlert({
				isOpen: true,
				message: baseError.response?.data.message || "",
				type: "error",
			});
		},
	});
};
