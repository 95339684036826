export const getStorySourceCode = (urlString: string) => {
	try {
		const url = new URL(urlString);
		const queryParams = new URLSearchParams(url.search);
		return queryParams.get("story_source_code") || "";
	} catch (error) {
		console.error("Invalid URL:", urlString);
		return "";
	}
};
