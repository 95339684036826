import { useContext } from "react";
import {
	BaseErrorResponse,
	BaseResponse,
	ILogin,
	IRefreshAccessKey,
	IUser,
} from "../../../interfaces";
import { useMutation } from "@tanstack/react-query";
import { AlertContext } from "../../../App";
import { AxiosError } from "axios";
import { axiosInstanceNoAuth } from "./client";
import Cookies from "js-cookie";
// import Cookies from "js-cookie"

export const useSignIn = (onOk?: (res?: IUser) => void, onErr?: Function) => {
	const { setModalAlert } = useContext(AlertContext);

	return useMutation<BaseResponse<IUser>, AxiosError, ILogin>({
		mutationKey: ["SIGN IN"],
		mutationFn: (params: ILogin): Promise<BaseResponse<IUser>> =>
			axiosInstanceNoAuth.post(
				`/login?user=${params.user}&password=${params.password}`,
				{}
			),
		onSuccess: (res) => {
			if (res?.data) {
				Cookies.set("_access_token", res.data.access_key, {
					expires: res.data.expiration,
				});
				Cookies.set("_expiration", res.data.expiration.toString(), {
					expires: Infinity,
				});
				onOk && onOk(res?.data);
			}
		},
		onError: (err) => {
			const baseError = err as BaseErrorResponse;
			setModalAlert({
				isOpen: true,
				message: baseError.response?.data.message || "",
				type: "error",
			});
			onErr && onErr(err);
		},
	});
};

export const useRefreshAccessToken = () => {
	const { setModalAlert } = useContext(AlertContext);
	return useMutation<BaseResponse<IRefreshAccessKey>, AxiosError, string>({
		mutationKey: ["REFRESH ACCESS TOKEN"],
		mutationFn: (
			access_key: string
		): Promise<BaseResponse<IRefreshAccessKey>> =>
			axiosInstanceNoAuth.post(
				`/refresh-access-key?access_key=${access_key}`,
				{}
			),
		onSuccess: (res) => {
			if (res?.data) {
				Cookies.set("_access_token", res.data.new_access_key, {
					expires: res.data.new_expiration,
				});
				Cookies.set("_expiration", res.data.new_expiration.toString(), {
					expires: Infinity,
				});
			}
		},
		onError: (err) => {
			const baseError = err as BaseErrorResponse;
			setModalAlert({
				isOpen: true,
				message: baseError.response?.data.message || "",
				type: "error",
			});
		},
	});
};

export const checkIsExpired = () => {
	const expiration = Number(Cookies.get("_expiration"));
	const timeCurrent = Math.floor(Date.now() / 1000);
	return timeCurrent > expiration;
};
