import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";

const Footer: React.FC = () => {
	const StyledContainer = styled(Container)({
		padding: "0.5rem 0",
	}) as typeof Container;

	return (
		<StyledContainer maxWidth="lg" component="footer">
			<Typography sx={{ fontSize: "0.6rem", textAlign: "center" }}>
				Copyright© SATO HOLDINGS CORPORATION. All Rights Reserved.
			</Typography>
		</StyledContainer>
	);
};

export default Footer;
