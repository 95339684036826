import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CommonLayout from "./components/layouts/CommonLayout";
import Home from "./components/pages/Home";
import { AppUrl } from "./components/utils/constants/AppUrl";
import Login from "./components/pages/Login";
import AlertMessage from "./components/utils/AlertMessage";
import Cookies from "js-cookie";
import { checkIsExpired, useRefreshAccessToken } from "components/lib/api/auth";
import Main from "components/pages/Memorize";

export const AlertContext = createContext(
	{} as {
		modalAlert: {
			isOpen: boolean;
			message: string | string[];
			type: "error" | "success" | "info" | "warning";
		};
		setModalAlert: Dispatch<
			SetStateAction<{
				isOpen: boolean;
				message: string | string[];
				type: "error" | "success" | "info" | "warning";
			}>
		>;
	}
);

function App() {
	const [modalAlert, setModalAlert] = useState<{
		isOpen: boolean;
		message: string | string[];
		type: "error" | "success" | "info" | "warning";
	}>({
		isOpen: false,
		message: "",
		type: "info",
	});
	const navigate = useNavigate();
	const location = useLocation();
	const handleRefreshToken = useRefreshAccessToken();
	const accessToken = Cookies.get("_access_token") as string;

	useEffect(() => {
		if (!accessToken) {
			if (location.pathname !== AppUrl.LOGIN) {
				navigate(AppUrl.LOGIN);
			}
		} else {
			const isExpired = checkIsExpired();
			if (isExpired) {
				handleRefreshToken.mutate(accessToken);
			}
			if (location.pathname === AppUrl.LOGIN) {
				navigate(AppUrl.HOME);
			}
		}
	}, [accessToken, handleRefreshToken, navigate, location]);

	return (
		<AlertContext.Provider value={{ modalAlert, setModalAlert }}>
			<Routes>
				<Route
					path={AppUrl.HOME}
					element={
						<CommonLayout title="自動認識記憶術 - DEMO">
							<Home />
						</CommonLayout>
					}
				/>
				<Route
					path={AppUrl.LOGIN}
					element={
						<CommonLayout title="ログイン" center>
							<Login />
						</CommonLayout>
					}
				/>
				<Route
					path={AppUrl.PRESCHOOL}
					element={
						<CommonLayout title="自動認識記憶術 - DEMO">
							<Main />
						</CommonLayout>
					}
				/>
				<Route
					path={AppUrl.GRADE_1}
					element={
						<CommonLayout title="自動認識記憶術 - DEMO">
							<Main />
						</CommonLayout>
					}
				/>
			</Routes>
			<AlertMessage
				open={modalAlert.isOpen}
				setOpen={(open: boolean) =>
					setModalAlert({ isOpen: open, message: "", type: "warning" })
				}
				severity={modalAlert.type}
				message={modalAlert.message}
			/>
		</AlertContext.Provider>
	);
}

export default App;
