import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "components/utils/constants/AppUrl";

const ChooseButton = styled(Button)({
	flexGrow: 1,
	textTransform: "none",
	minWidth: "150px",
	minHeight: "100px",
	fontSize: 24,
});

const Home = () => {
	const navigate = useNavigate();

	return (
		<Box sx={{ margin: "0 20px" }}>
			<Typography align="center" fontSize={18}>
				ストーリーモードを選択してください
			</Typography>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: 4,
					marginTop: 2,
				}}
			>
				<ChooseButton
					variant="contained"
					onClick={() => {
						navigate(AppUrl.PRESCHOOL);
					}}
				>
					園児向け
				</ChooseButton>
				<ChooseButton
					variant="contained"
					onClick={() => {
						navigate(AppUrl.GRADE_1);
					}}
				>
					小学校低学年向け
				</ChooseButton>
			</Box>
		</Box>
	);
};

export default Home;
