import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { apiUrl, AppUrl } from "components/utils/constants/AppUrl";

const client = axios.create({
	baseURL: apiUrl + "/api/v1",
});

const shouldRedirectToLogin = true;

class Axios {
	private api: AxiosInstance;

	constructor(baseURL: string, noAuth: boolean) {
		this.api = axios.create({
			baseURL,
		});
		if (!noAuth) {
			this.api.interceptors.request.use(
				(config) => {
					const accessToken = this.getAccessToken();

					if (accessToken) {
						config.headers["Authorization"] = `${accessToken}`;
					}
					config.headers["Content-Type"] = "application/json";

					return config;
				},
				(error) => {
					return Promise.reject(error);
				}
			);
		} else this.handleNoAuthsponse(this.api);
	}

	handleResponse(axios: AxiosInstance) {
		axios.interceptors.response.use(
			async (response: AxiosResponse) => {
				return response;
			},
			async (error) => {
				if (error.response.status === 403 && typeof window !== "undefined") {
					//here show error when role of user not true
				}
				if (error.response.status === 401 && typeof window !== "undefined") {
					console.log("Your session expired!");

					// client.delete("auth/sign_out", {
					// 	headers: {
					// 		"access-token": Cookies.get("_access_token"),
					// 		client: Cookies.get("_client"),
					// 		uid: Cookies.get("_uid"),
					// 	},
					// });
					if (shouldRedirectToLogin) {
						window.location.href = AppUrl.HOME;
					}
				}
				return Promise.reject(error);
			}
		);
	}
	handleNoAuthsponse(axios: AxiosInstance) {
		axios.interceptors.response.use(
			async (response: AxiosResponse) => {
				return response;
			},
			async (error) => {
				return Promise.reject(error);
			}
		);
	}
	async get<T>(url: string, configs?: AxiosRequestConfig): Promise<T> {
		try {
			const response = await this.api.get<T>(url, configs);
			return response.data;
		} catch (error) {
			console.error("GET request failed:", error);
			throw error;
		}
	}

	async post<T>(
		url: string,
		data: any,
		configs?: AxiosRequestConfig
	): Promise<T> {
		try {
			const response = await this.api.post<T>(url, data, configs);

			return response.data;
		} catch (error) {
			console.error("POST request failed:", error);
			throw error;
		}
	}

	async put<T>(
		url: string,
		data?: any,
		configs?: AxiosRequestConfig
	): Promise<T> {
		try {
			const response = await this.api.put<T>(url, data, configs);
			return response.data;
		} catch (error) {
			console.error("PUT request failed:", error);
			throw error;
		}
	}

	async patch<T>(
		url: string,
		data?: any,
		configs?: AxiosRequestConfig
	): Promise<T> {
		try {
			const response = await this.api.patch<T>(url, data, configs);
			return response.data;
		} catch (error) {
			console.error("PATCH request failed:", error);
			throw error;
		}
	}

	async delete<T>(url: string, configs?: AxiosRequestConfig): Promise<T> {
		try {
			const response = await this.api.delete<T>(url, configs);
			return response.data;
		} catch (error) {
			console.error("DELETE request failed:", error);
			throw error;
		}
	}
	private getAccessToken(): string {
		return Cookies.get("_access_token") as string;
	}
	private getExpiration(): string {
		return Cookies.get("_expiration") as string;
	}
}

export const axiosInstanceAuth = new Axios(apiUrl, false);
export const axiosInstanceNoAuth = new Axios(apiUrl, true);

export default client;
