import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { ILogin } from "../../interfaces";
import { styled } from "@mui/material/styles";
import { useSignIn } from "components/lib/api/auth";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "components/utils/constants/AppUrl";

const SignSubmitButton = styled(Button)({
	marginTop: 2,
	flexGrow: 1,
	textTransform: "none",
});

const Login = () => {
	const [loginData, setLoginData] = useState<ILogin>({
		user: "",
		password: "",
	});
	const navigate = useNavigate();

	const handleSignIn = useSignIn((res) => {
		navigate(AppUrl.HOME);
	});
	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		handleSignIn.mutate(loginData);
	};

	return (
		<Box
			sx={{
				padding: "0 32px 16px",
				display: "flex",
				flexGrow: 1,
			}}
		>
			<form noValidate autoComplete="off">
				<TextField
					sx={{ backgroundColor: "#ffffff" }}
					autoComplete="username"
					variant="outlined"
					required
					fullWidth
					label="ID"
					value={loginData.user}
					margin="dense"
					onChange={(e) => setLoginData({ ...loginData, user: e.target.value })}
				/>
				<TextField
					sx={{ backgroundColor: "#ffffff" }}
					autoComplete="off"
					variant="outlined"
					required
					fullWidth
					label="パスワード"
					type="password"
					value={loginData.password}
					margin="dense"
					onChange={(e) =>
						setLoginData({ ...loginData, password: e.target.value })
					}
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "80%",
						margin: "auto",
					}}
				>
					<SignSubmitButton
						type="submit"
						variant="contained"
						size="large"
						fullWidth
						color="primary"
						disabled={
							!loginData.user || !loginData.password || handleSignIn.isPending
						}
						onClick={handleSubmit}
					>
						ログイン
					</SignSubmitButton>
				</Box>
			</form>
		</Box>
	);
};

export default Login;
